.bg-about-us-wrapper {
  background: linear-gradient(
    #132246 0%,
    #1f4486 10%,
    #3a79b3 40%,
    #5cb3e1 50%,
    #94fefc 70%
  );
}

.bg-about-us-container {
  background-image: url("/public/images/aboutUs/about_bg.png");
  background-repeat: no-repeat;
  background-position: bottom 100px center;  
  background-size: cover;
}

.bg-book {
  background-image: url("/public/images/aboutUs/book_container.png");
	background-repeat: no-repeat;
  background-position: center center;
}

.bg-about-us-container-vertical {
  background-image: url("/public/images/aboutUs/mobile/libraryMobil1.png");
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: cover;
}

.carousel-control-prev-icon, .carousel-control-next-icon {
  background-color: red;
  border-radius: 45px;
}