.btn-base-properties {
  letter-spacing: 0.1px;
  white-space: nowrap;
  font-size: 1rem;
  font-family: "Arial";
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  border-radius: 30px;
  width: 100%;
}

@media screen and (max-width: 600px) {
  .btn-base-properties {
    font-size: 0.6rem;
  }
}

.short-btn {
  padding: 1em 2.3em;
  align-items: center;
}

@media screen and (max-width: 600px) {
  .short-btn {
    padding: 0.8em 1.3em;
    
  }
}

.long-btn {
  padding: 1em 4.3em;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 1400px) {
  .long-btn {
    padding: 0.8em 2.3em;
  }
}

@media screen and (max-width: 425px) {
  .long-btn {
    padding: 0.8em 1.3em;
  }
}

.btn-icon, .mini-btn-icon {
  width: 20px;
  height: 18.35px;
}

@media screen and (max-width: 600px) {
  .btn-icon {
    width: 12px;
    height: 10.35px;
  }
}

.mini-btn {
  padding: 1.2em;
}

.primary-btn {
  border-radius: 60px;
  background: #a533f2;
  background: linear-gradient(to bottom, #a533f2, #7e10c8);
  color: #fff;
  border: 0;
}

.primary-btn:hover {
  background: #c176f4;
  background: linear-gradient(to bottom, #c176f4, #a14fd8);
}

.secondary-btn {
  position: relative;
  color: #630bad;
}

.secondary-btn:hover {
  color: #fff;
  background: #d7a7ff;
}

.secondary-btn::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 30px;
  border: 3px solid transparent;
  background: #630bad border-box;
  -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: destination-out;
  mask-composite: exclude;
}