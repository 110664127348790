@tailwind base;
@tailwind components;
@tailwind utilities;

/* Override browser default */
html,
body {
  margin: 0;
  padding: 0;
}

/* Include padding and border in element width and height */
* {
  box-sizing: border-box;
}

/* Font-face */
@font-face {
  font-family: "Baloo2ExtraBold";
  src: local("Baloo2-ExtraBold"),
    url("/public/fonts/Baloo2-ExtraBold.ttf") format("truetype");
}

@font-face {
  font-family: "Baloo2Bold";
  src: local("Baloo2-Bold"),
    url("/public/fonts/Baloo2-Bold.ttf") format("truetype");
}

/* Headings */
h1 {
  font-family: "Baloo2ExtraBold";
  color: #93fefc;
  text-align: center;
  margin-bottom: 50px;
}

h2, h3, h4 {
  font-family: "Baloo2Bold";
  text-align: center;
}

h2 {
  font-size: 1.2em;
}

h3 {
  font-size: 1.1em;
  margin-top: 50px;
}

/* Paragraphs, lists and address */
p, ul, ol, address {
  font-family: "Arial";
  font-weight: bold;
  color: #fff;
}

p {
  font-size: 1em;
  line-height: 1.5;
}

ol > li {
  margin-bottom: 1em;
}

li {
  text-align: left;
  display: list-item;
}

address {
  color: #fff065;
  font-style: bold;
  font-size: 1em;
}

/* Anchor links */
a {
  text-decoration: underline;
  word-break: break-all;
}

/* Line breaks */
br {
  content: "";
  display: block;
  margin-bottom: .5em;
}

/* Arrow icons for dropdown menus from the desktop navbar */
.open-dropdown-arrow-icon {
  background-image: url("/public/images/navImages/opened_dropdown_arrow.svg");
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: bottom center;
}

.close-dropdown-arrow-icon {
  background-image: url("/public/images/navImages/closed_dropdown_arrow.svg");
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: bottom center;
}

/* Shop link on the mobile nav menu */
.shop-nav-link:hover .shop-nav-text {
  border-bottom-color: #fff;
}

/* General gradient wrapper for certain pages  */
.bg-general-wrapper {
  background: linear-gradient(#132246 0%, #1f4486 5%, #3a79b3 10%, #5cb3e1 30%, #94fefc 100%);
}

/* Box styles for introduction on the legal pages */
.bg-policy-intro {
  background: rgb(76, 91, 123);
  background: rgba(76, 91, 123, 0.5);
  box-shadow: inset 0 15px 30px rgb(255 255 255 / 50%), 8px 8px 10px 0px rgb(0 0 0 / 30%);
}

/* Footer on the legal pages */
.bg-content-container {
  background-image: url('/public/images/home/sand_beach.svg');
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: 100%;
}

/* Media Queries */
@media screen and (max-width: 950px) {
  h1 {
    font-size: 1em;
    margin-bottom: 30px;
  }

  h2 {
    font-size: .60em;
  }

  h3 {
    font-size: .50em;
  }

  p {
    font-size: .55em;
  }

  address {
    font-size: .55em;
  }
}

@media screen and (max-width: 630px) {
  p {
    font-size: .50em;
  }

  address {
    font-size: .50em;
  }
}

@media screen and (max-width: 425px) {
  h1 {
    font-size: .70em;
  }
}