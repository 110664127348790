/* Desktop Footer */
.footer {
  width: 100%;
  margin-top: -2px;
  bottom: 0;
}

.seashore-desktop-footer {
  padding-top: 30.3%;
  background-image: url("/public/images/footerImages/diving_ziggy.svg"), url("/public/images/footerImages/seashore-desktop-footer.svg");
  background-repeat: no-repeat, no-repeat;
  background-position: top 80% left 0, center bottom 96%;
  background-size: 12%, cover;
}

@media screen and (max-width: 1160px) {
  .seashore-desktop-footer {
    background-image: url("/public/images/footerImages/diving_ziggy.svg"), url("/public/images/footerImages/seashore-desktop-footer.svg");
    background-repeat: no-repeat, no-repeat;
    background-position: top 70% left 0, center bottom -1%;
  }
}

@media screen and (max-width: 920px) {
  .seashore-desktop-footer {
    background-image: url("/public/images/footerImages/diving_ziggy.svg"), url("/public/images/footerImages/seashore-desktop-footer.svg");
    background-repeat: no-repeat, no-repeat;
    background-position: top 56% left -2%, center bottom -1%;
    background-size: 18%, cover;
  }
}

.waves-desktop-footer {
  padding-top: 30.3%;
  background-image: url("/public/images/footerImages/diving_ziggy.svg"), url("/public/images/footerImages/waves-desktop-footer.svg");
  background-repeat: no-repeat, no-repeat;
  background-position: top 80% left 0, center bottom 100%;
  background-size: 12%, cover;
}

@media screen and (max-width: 1160px) {
  .waves-desktop-footer {
    background-image: url("/public/images/footerImages/diving_ziggy.svg"), url("/public/images/footerImages/waves-desktop-footer.svg");
    background-repeat: no-repeat, no-repeat;
    background-position: top 70% left 0, center bottom 100%;
  }
}

@media screen and (max-width: 920px) {
  .waves-desktop-footer {
    background-image: url("/public/images/footerImages/diving_ziggy.svg"), url("/public/images/footerImages/waves-desktop-footer.svg");
    background-repeat: no-repeat, no-repeat;
    background-position: top 56% left -2%, center bottom 100%;
    background-size: 18%, cover;
  }
}

.footer-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 62%;
  margin: 0 auto;
  padding: 10px;
  position: relative;
  top: -80px;
}

.footer-content {
  display: flex;
  justify-content: center;
  gap: 50px;
}

.footer-segment {
  width: 33%;
}

.footer-segment li {
  list-style: none;
}

.link-heading,
.access-link {
  color: #1e3e7a;
}

.stars-desktop-footer .link-heading,
.stars-desktop-footer .access-link {
  color: #fff;
}

.link-heading {
  font-family: "Baloo2Bold";
  font-size: 1.4em;
  text-transform: uppercase;
  margin-bottom: .50em;
  padding-left: 40px;
  border: 4px solid transparent;
  text-align: left;
  letter-spacing: 2px;
}

.access-link {
  display: inline-block;
  font-family: "Baloo2Bold";
  text-decoration: none;
  transition: all 0.3s ease-out;
  line-height: 1.8;
  font-size: 1.1em;
  font-weight: bold;
  border: 4px solid transparent;
}

.access-link:hover {
  transform: translate(0, -5px);
}

.socials-img {
  width: 100%;
}

@media screen and (max-width: 1440px) {
  .link-heading {
    font-size: 1.3em;
  }

  .access-link {
    font-size: 1em;
  }
}

@media screen and (max-width: 1346px) {
  .footer-content {
    gap: 30px;
  }
}

@media screen and (max-width: 1248px) {
  .link-heading {
    font-size: 1.2em;
  }

  .access-link {
    font-size: .9em;
  }
}

@media screen and (max-width: 1160px) {
  .footer-wrapper {
    width: 72%;
  }

  .footer-content {
    gap: 0;
  }
}

@media screen and (max-width: 920px) {
  .footer-wrapper {
    width: 82%;
  }
}

@media screen and (max-width: 810px) {
  .link-heading {
    font-size: 1.1em;
  }

  .access-link {
    font-size: .8em;
  }
}

@media screen and (max-width: 740px) {
  .link-heading {
    font-size: 1.1em;
  }

  .access-link {
    font-size: .7em;
  }
}

@media screen and (max-width: 670px) {
  .footer-wrapper {
    width: 85%;
  }
}

/* Mobile Footer */
.seashore-mobile-footer {
  background:
    url("/public/images/footerImages/diving_ziggy.svg") top 40% left 3%/30% no-repeat,
    url("/public/images/footerImages/seashore-mobile-footer.svg") top center/100% no-repeat,
    linear-gradient(to bottom, #9ddff6 10%, #69c2ef 40%, #0681BF 90%);
  padding: 150px 0 20px;
}

.waves-mobile-footer {
  background:
    url("/public/images/footerImages/diving_ziggy.svg") top 40% left 3%/30% no-repeat,
    url("/public/images/footerImages/waves-mobile-footer.svg") top center/100% no-repeat,
    linear-gradient(to bottom, transparent, #9ddff6 10%, #69c2ef 40%, #0681bf 90%);
  padding: 150px 0 20px;
}

.mobile-footer-segment {
  padding-bottom: 1em;
}

.mobile-link-heading {
  font-family: "Baloo2Bold";
  font-size: 1.4em;
  color: #1e3e7a;
  text-transform: uppercase;
  text-align: center;
  text-decoration: underline;
  letter-spacing: 2px;
}

.mobile-footer-list {
  padding-left: 0;
}

.mobile-footer-list li {
  text-align: center;
  list-style: none;
  line-height: 40px;
}

.mobile-access-link {
  text-transform: capitalize;
  text-decoration: none;
  font-family: "Baloo2Bold";
  font-weight: bold;
  font-size: 1.1em;
  display: block;
  color: #1e3e7a;
}

.mobile-access-link:hover {
  background-color: rgba(201, 229, 247, 0.5);
  display: block;
  width: 100%;
}

/* Charity social links and status */
.charity-socials {
  display: flex;
  justify-content: space-between;
  gap: 15px;
  margin-left: 36px;
  width: 52%;
}

.charity-status {
  font-size: 0.9em;
  font-family: "Arial";
  text-align: center;
  padding: 10px;
  color: #fff;
  margin-bottom: -70px;
}

@media screen and (max-width: 1248px) {
  .charity-socials {
    gap: 10px;
  }
}

@media screen and (max-width: 920px) {
  .charity-socials {
    gap: 5px;
  }
}

@media screen and (max-width: 651px) {
  .charity-socials {
    gap: 15px;
    margin: 0 auto;
    width: 40%;
  }

  .charity-status {
    text-decoration: underline;
    margin-bottom: 0;
  }
}

@media screen and (max-width: 460px) {
  .charity-status {
    font-size: 0.6em;
  }
}

@media screen and (max-width: 400px) {
  .charity-socials {
    width: 50%;
  }

  .charity-status {
    font-size: 0.5em;
  }
}

/* Site build info */
.build-info {
  font-size: 0.9em;
  font-family: "Arial";
  text-align: right;
  font-weight: bold;
  padding: 10px;
  color: #fff;
}

@media screen and (max-width: 460px) {
  .build-info {
    font-size: 0.6em;
  }
}

@media screen and (max-width: 400px) {
  .build-info {
    font-size: 0.5em;
  }
}