
.accessibility {
  display: none;
}

.accessibility.show {
  display: block;
  background: #cff0ff;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 15px 50px;
}

@media screen and (max-width: 992px) {
  .accessibility.show {
    padding: 10px;
  }
}

.font-size-controls {
  display: flex;
  gap: 15px;
}

.access-btn {
  background: linear-gradient(#fff, #f2f2f2, #d9d9d9);
  border: 3px solid #d9d9d9;
  border-radius: 50px;
  box-shadow: 1px 1px 5px 0px #c0c0c0, 1px 1px 5px 0px #b2b2b2, 1px 1px 5px 0px #c0c0c0, 1px 1px 5px 0px #1e3e7a;
  padding: 4px 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

@media screen and (max-width: 600px) {
  .font-size-controls {
    gap: 10px;
  }

  .access-btn {
    border: 1px solid #d9d9d9;
    padding: 2px;
  }
}

.header {
  background: #58ccff;
  width: 100%;
  opacity: 1;
  display: flex;
  justify-content: space-between;
  padding: 0 50px;
  gap: 15px;
}

@media screen and (max-width: 992px) {
  .header {
    padding: 0 15px;
  }
}

@media screen and (max-width: 600px) {
  .header {
    padding: 20px 15px;
  }
}

.logos {
  display: flex;
  align-items: center;
  gap: 10px;
}

@media screen and (max-width: 600px) {
  .logos {
    gap: 10px;
    width: 140px;
  }
}

.header_btn {
  display: flex;
  align-items: center;
}

.donate-link {
  text-decoration: none;
  border: 4px solid transparent;
  border-radius: 60px;
}

.donate-link:active, .donate-link:focus {
  outline: none !important;   
  border: 4px solid #f7f034;
}

.accessibility-btn {
  background: transparent;
  padding: 0;
  border: 4px solid transparent;
  border-radius: 60px;
}

.accessibility-btn:active, .accessibility-btn:focus {
  outline: none !important;   
  border: 4px solid #f7f034;
}

.logo-container {
  border: 4px solid transparent;
}

.logo-container:active, .logo-container:focus {
  outline: none !important;   
  border: 4px solid #e3f0ff;
}